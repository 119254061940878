import Randomstring from "randomstring";
import {apiHelper, apiHelperBlockchain} from "./apiHelper";

export const convertUtc = (date) => {
    const monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    let newDate = new Date(date);
    let month = newDate.getMonth();
    let day = newDate.getDate();
    let year = newDate.getFullYear();
    return `${monthArr[month]} ${day}, ${year}`;
}
export const formatDateToLocaleStr = (date) =>{
  const initDate = new Date(date);
  const formattedDate = initDate.toLocaleDateString('en-GB', {
    day: '2-digit', month: 'short', year: 'numeric'
  }).replace(/ /g, ', ');
  return formattedDate
}

export const calcDate = (date1, date2) => {
    let diff = Math.floor(date1.getTime() - date2.getTime());
    let day = 1000 * 60 * 60 * 24;

    let mins = Math.floor((diff / 1000) / 60);
    let hrs = Math.floor((diff / 3600) / 1000);

    let days = Math.floor(diff / day);
    let months = Math.floor(days / 31);
    let years = Math.floor(months / 12);

    let str = "";
    if (years !== 0) { str += years + " Years"; }
    else if (months !== 0) { str += months + " Months"; }
    else if (days !== 0) { str += days + " Days"; }
    else if (hrs !== 0) { str += hrs + " Hours"; }
    else if (mins === "" || mins === 0) { str += "1 Minutes"; }
    else if (mins !== 0) { str += mins + " Minutes"; }

    return str + " ago";
}

export const capitalize =(str)=> str.charAt(0).toUpperCase() + str.slice(1);


export const stringTruncate = (str) => str.substring(1, 8) + "..." + str.slice(-4);


export const walletAddressTruncate = (str) => str.slice(0,5) + "..." + str.slice(-4);


export const generateRandomString =(length) => Randomstring.generate(length);

export const getMetadataForIdPages = async(currLocale) =>{
  try {
    const postData = {
      language: currLocale,
      pageName: 'UPYO-id'
    }
    const resMeta = await apiHelper(`metadata/getNftMetadata`, "POST", postData, null);
    return resMeta.data.status ? resMeta.data.data : null
  } catch (error) {
    console.log(error);
    return null
  }
}

export const getDateDiffHours = (date) => {
    const date2 = new Date(date);
    const date1 = Date.now();
    return Math.abs(date1 - date2) / 36e5;
}
const prepareDataForApi = (stateData) => {
    const { page, size, network, type, category_id,
        creator, currency, min_price, max_price, isServer} = stateData;
    let nPass = {
        page, size
    };
    if (category_id !== 0 && category_id !== '0') {
        nPass.category = category_id;
    }
    const userId = !isServer && localStorage.getItem('user_id');
    if (userId) {
        nPass.user_id = userId;
    }

    if (network) { nPass.chainIndex = network; }
    if (type) { nPass.nft_type = type; }
    if (creator) { nPass.is_creator_verified = creator; }
    if (currency && currency !== 0) { nPass.priceType = currency; }
    if (min_price && min_price !== 0) { nPass.min_price_filter = parseFloat(min_price); }
    if (max_price && max_price !== 0) { nPass.max_price_filter = parseFloat(max_price); }
    return nPass;
}
export const discoverAllNFTList = async (stateData, apiName) => {
    const { currency, min_price, max_price} = stateData;
    const nPass = prepareDataForApi(stateData);

    if (parseInt(min_price) || parseInt(max_price)) {
        nPass.priceType = currency;
    }
    nPass.home = 1;

    const resp = await apiHelperBlockchain(apiName, 'POST', nPass);
    if (resp.data.status) {
        return resp.data.data
    }
    return [];
}
export const exploreAllNftList = async (stateData, apiName) => {
    const { chainIndex, filter_search, filter_price_sort} = stateData;

    const nPass = prepareDataForApi(stateData);

    nPass.mintingType = stateData.mintingType;

    if (!chainIndex) { nPass.priceType = 0 }
    if (filter_search) { nPass.search = filter_search }

    const filterPriceSort = parseInt(filter_price_sort);
    if (filterPriceSort === 2) { nPass.recently_listed = -1; }
    else if (filterPriceSort === 0) { nPass.priceSort = 1; }
    else if (filterPriceSort === 1) { nPass.priceSort = -1; }

    const resp = await apiHelperBlockchain(apiName, 'POST', nPass)
    if (resp.data.status) {
        return {
            allNftList: resp.data.data.data,
            totalNfts: resp.data.data.totalNfts
        }
    }
    return {}
}
export const getPriceTag = (t, stateData, min_price, max_price, currency) => {
    let price_tag = '';
    const networkName = stateData.network === 1 ? t('common:eth') : t('common:matic')
    if (min_price) {
        price_tag = t('common:min') + min_price + ` ${currency === 1 ? t('common:USD') : (networkName)}`
    }
    if (max_price) {
        price_tag += `${min_price > 0 ? "," : ""}` + "Max " + max_price + ` ${currency === 1 ? t('common:USD') : (networkName)}`
    }
    return price_tag;
}
export const convertDateToTZD=(date)=>{
    const currDate = new Date(date);
    const dateTimeIsoString = currDate.toISOString();
// split by dot
    const currDateTime = dateTimeIsoString.split('.')[0];
    const timeZoneOffset = currDate.getTimezoneOffset();
    const positiveOffset = Math.abs(timeZoneOffset)
    const timeOffsetInHours = -(timeZoneOffset/60)
    const minZone = (positiveOffset - Math.floor(timeOffsetInHours) * 60)
    const symbolOffset = timeZoneOffset > 0 ? '-' : '+' ;
    const hourOffset = Math.floor(timeOffsetInHours) < 10 ? 0 : '';
    const minOffset = minZone < 10 ? 0 : '';
    const tzd = symbolOffset + hourOffset + Math.floor(timeOffsetInHours) + ":" + minOffset + minZone
    return currDateTime + tzd;
}

export const ipfsURLFormat = (imgUrl)=>{
    if (imgUrl.includes("ipfs://")) {
      return `https://ipfs.io/ipfs/${imgUrl.split("ipfs://")[1]}`;
    }else if(imgUrl.includes("/ipfs/")){
      return `https://ipfs.io/ipfs/${imgUrl.split("/ipfs/")[1]}`;
    }else if(imgUrl.includes("ipfs.dweb.link")){
      let intermediate = imgUrl.split(".ipfs.dweb.link")[0]
      return `https://ipfs.io/ipfs/${intermediate?.split("https://")[1]}`;
    }
    return imgUrl;
}

export const ipfsURLFunc = (nftDetails)=> {
    let ipfsurl = ""
    const regex = new RegExp(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/)
    let ipfsLink = nftDetails.imageIPFS
    if (nftDetails.imageIPFS.includes("ipfs://") && !nftDetails.imageIPFS.includes("ipfs/")) {
      ipfsLink = nftDetails.imageIPFS.split("ipfs://")[1]
      ipfsurl = "https://ipfs.io/ipfs/" + ipfsLink
    }
    else if (nftDetails.imageIPFS.includes("ipfs/")) {
      ipfsLink = nftDetails.imageIPFS.split("ipfs/")[1]
      ipfsurl = ipfsurl = "https://ipfs.io/ipfs/" + ipfsLink

    }
    else if (regex.test(nftDetails.imageIPFS)) {
      ipfsurl = nftDetails.imageIPFS
    }
    else {
      ipfsurl = "https://ipfs.io/ipfs/" + ipfsLink
    }
    return ipfsurl;
}

export const nFormatter = (num, type) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    if (type === "floorPrice") { return num.toFixed(4); }
    if (type === "marketCap") { return num.toFixed(1); }
    if (type === "totalVolume") { return num.toFixed(1); }
    return num;
}