import React, { useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import dynamic from "next/dynamic";
// Pugins
import {useTranslation, withTranslation} from 'next-i18next';

// Styles
import "../../../node_modules/slick-carousel/slick/slick.css";
import styles from "./Discover.module.scss";
import {useRouter, withRouter} from 'next/router';

// API helper
import {ShimmerSimpleGallery} from "react-shimmer-effects";
import {discoverAllNFTList} from "../../../helper/utilityHelper";
// componenets
const BidsList = dynamic(() => import('../../home/HotBid/Bidslist'))
const NotFoundSection = dynamic(() => import('../../NotFoundSection'))
const CategoryFilterDesktop = dynamic(() => import('../../common/categoryFilter/CategoryFilterDesktop'))

const DiscoverSection = (props) => {
    const router = useRouter();
    const [categoryList] = useState(props.categoryList);
    const [cList] = useState("");
    const [category_id, setCategoryId] = useState(0);
    const [all_nft_list, setAllNftList] = useState(props.allNftList);
    const [nftLoader, setNftLoader] = useState(false);
    const [page, setPage] = useState(1);
    const size = 8;
    const [network, setNetwork] = useState("");
    const [type, setType] = useState("");
    const [creator, setCreator] = useState("");
    const [currency, setCurrency] = useState(0);
    const [min_price, setMinPrice] = useState(0);
    const [max_price, setMaxPrice] = useState(0);

    const getAllNFTList = async () => {
        setNftLoader(true);
        try{
            const stateData = { page, size, network, type, category_id,
                creator, currency, min_price, max_price }
            const allNftList = await discoverAllNFTList(stateData, 'get-global-nft');
            setAllNftList(allNftList);
            setNftLoader(false);
        }catch(error){
            setNftLoader(false);
            console.error(error)
        }
    }

    const loadMoreNft = () => {
        let cat = "";
        if (category_id !== 0) {
            let cItem = categoryList.find((e) => e._id === category_id);
            let catName = cItem.category_name.replace(/ /g, "-");
            cat = decodeURI(catName);
        }

        // redirect to dedicated page
        router.push('/nft/category/' + cat + '?start=1&chain=' + network + "&type=" + type + "&creator=" + creator + "&currency=" + currency + "&min_price=" + min_price + "&max_price=" + max_price);
    }

    useEffect(()=>{
        (async () => await getAllNFTList())();
    },[category_id, page, network, min_price, max_price])

    const changeCategory = (e) => {
        setAllNftList([]);
        setCategoryId(e);
        setPage(1);
    }

    const changeNetwork = (item) => {
        setAllNftList([])
        setNetwork(item);
        setPage(1);
        setMinPrice(0);
        setMaxPrice(0);
    }

    const changeType = (item) => {
        setAllNftList([])
        setType(item);
        setPage(1);
    }

    const changeCreator = (item) => {
        setAllNftList([]);
        setCreator(item);
        setPage(1)
    }

    const applyPrice = (min_price, max_price, currency) => {
        setAllNftList([]);
        setCurrency(currency);
        setMinPrice(min_price);
        setMaxPrice(max_price);
        setPage(1);
    }

    const refreshMetadata = (db_id, new_item) => {

        let nData = all_nft_list;
        const indexOfItemInArray = nData.findIndex(q => q._id === db_id);

        if (indexOfItemInArray > -1) {
            nData[indexOfItemInArray].imageUrl = new_item.imageUrl;
        }
    }


    const { t } = useTranslation('common');

    return (
            <section className={`section ${styles.discoverIndex2} ${styles.discoverMain} ${props.customClass ? styles.customSection : ''}`}>
                <Container className={styles.container}>
                    {props.title && <h2 className={`h3 ${styles.hot__title}`}>{t('common:discover')} ✨</h2> }

                    <CategoryFilterDesktop
                        indexFilter={true}
                        categoryList={props.categoryList}
                        cList={cList}
                        currLocale={props.currLocale}
                        changeNetwork={changeNetwork}
                        changeType={changeType}
                        changeCreator={changeCreator}
                        changeCategory={changeCategory}
                        applyPrice={applyPrice}
                        isSortingShow={false}
                        isFire={false}
                    />

                    {nftLoader ?
                        <ShimmerSimpleGallery imageHeight={256} row={2} col={4} gap={20} caption />
                        : <>
                            { all_nft_list?.length > 0 && <Row className={styles.nftRows}>
                                {all_nft_list.map((info, idx) => {
                                    return (
                                        <Col xl={3} md={4} xs={6} key={idx}>
                                            <BidsList
                                                id={info._id}
                                                nftId={info.nft_id}
                                                signature={info.signature}
                                                put_on_sale={info.put_on_sale}
                                                imgURL={info.imageUrl}
                                                imageIPFS={info.imageIPFS}
                                                title={info.itemName}
                                                price={info.price && info.price}
                                                discounted_price={info.onSaleData?.discounted_price && parseFloat(info.onSaleData.discounted_price) > 0 ? info.onSaleData.discounted_price : ''}
                                                walletAddress={info.walletAddress}
                                                owner={info.owner}
                                                tokenId={info.nft_id}
                                                tokenQty={info.tokenQty}
                                                collection_id={info.collection_id}
                                                auctionType={info.auctionType}
                                                is_lazy_mint={info.is_lazy_mint ? info.is_lazy_mint : false}
                                                royalty={info.royalty}
                                                jsonIpfs={info.jsonIpfs}
                                                user={info.user && info.user}
                                                is_multiple={!!info.is_multiple}
                                                ownerList={info.ownerList && info.ownerList}
                                                nftUsdPrice={info.nftUsdPrice ? info.nftUsdPrice : 0}
                                                favoriteNFT={info.favoriteNFT ? info.favoriteNFT : {}}
                                                chainIndex={info.chainIndex ? info.chainIndex : 1}
                                                refreshMetadata={refreshMetadata}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row> }
                            { all_nft_list?.length === 0 && <NotFoundSection type="nft" /> }
                        </>
                    }

                    {all_nft_list && page === 1 && all_nft_list.length < size ?
                        null : (all_nft_list?.length > 0 &&
                            <div className="text-center" onClick={loadMoreNft}>
                                <div className="btn buttonStroke loadmoreBtn">
                                    {t('common:view_more')}
                                </div>
                            </div> )}

                </Container>
            </section>
        )

}
export default withTranslation()(withRouter(DiscoverSection));